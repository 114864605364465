import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'next/link';
import styles from './Links.module.scss';

function Links({ className, ...props }) {
    return (
        <ul className={classNames(styles.links, className)} {...props}>
            <li>
                <Link href="/about-us">
                    <a>About us</a>
                </Link>
            </li>
            <li>
                <Link href="/terms-and-conditions">
                    <a>Terms and conditions</a>
                </Link>
            </li>
            <li>
                <Link href="/privacy-policy">
                    <a>Privacy policy</a>
                </Link>
            </li>
            <li>
                <Link href="/cookies">
                    <a>Cookie policy</a>
                </Link>
            </li>
            <li>
                <Link href="/terms-of-use">
                    <a>Terms of use</a>
                </Link>
            </li>
        </ul>
    );
}

Links.propTypes = {
    className: PropTypes.string,
};

export default Links;
