import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import MenuItem from '../MenuItem/MenuItem';
import MenuLink from '../MenuLink/MenuLink';
import Icon from '../../../common/Icon/Icon';
import styles from './SubMenu.module.scss';

function SubMenu({
    href,
    children,
    links,
    isOpen,
    onBack,
    className,
    ...props
}) {
    return (
        <div
            className={classNames({
                [styles.subMenu]: true,
                [styles.isOpen]: isOpen,
                [styles[`col-${getColumnCount(links)}`]]: true,
                [className]: !!className,
            })}
            {...props}
        >
            <ul className={styles.items}>
                <MenuItem
                    className={classNames(styles.link, styles.backButton)}
                >
                    <button onClick={onBack}>
                        <Icon className={styles.icon} icon="chevron" />
                        Back
                    </button>
                </MenuItem>
                {href && (
                    <MenuLink
                        href={href}
                        className={classNames(styles.link, styles.parent)}
                    >
                        {children}
                    </MenuLink>
                )}
                {links.map(({ href, children, links, ...props }) => (
                    <MenuLink
                        key={children}
                        href={href}
                        links={links}
                        className={styles.link}
                        {...props}
                    >
                        {children}
                    </MenuLink>
                ))}
            </ul>
        </div>
    );
}

function getColumnCount(links) {
    let columnCount = 0;
    if (links && links.length > 0) {
        if (links.length > 36) {
            columnCount = 4;
        } else if (links.length >= 22) {
            columnCount = 3;
        } else {
            columnCount = 2;
        }
    }

    return columnCount;
}

SubMenu.propTypes = {
    href: PropTypes.string,
    children: PropTypes.node,
    links: PropTypes.array,
    isOpen: PropTypes.bool.isRequired,
    onBack: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default SubMenu;
