import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import HeaderTop from './HeaderTop/HeaderTop';
import HeaderMenu from './HeaderMenu/HeaderMenu';
import styles from './Header.module.scss';

function Header({ className, ...props }) {
    return (
        <header className={classNames(styles.header, className)} {...props}>
            <HeaderTop className={styles.headerTop} />
            <HeaderMenu className={styles.headerMenu} />
        </header>
    );
}

Header.propTypes = {
    className: PropTypes.string,
};

export default Header;
