import React from 'react';
import PropTypes from 'prop-types';
import {
    displayTelephoneNumber,
    telephoneNumber,
} from '../../../utils/constants';

function PhoneNumber({ className, ...props }) {
    return (
        <a className={className} href={`tel:${telephoneNumber}`} {...props}>
            {displayTelephoneNumber}
        </a>
    );
}

PhoneNumber.propTypes = {
    className: PropTypes.string,
};

export default PhoneNumber;
