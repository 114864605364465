import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import Link from 'next/link';
import classNames from 'classnames';
import MenuItem from '../MenuItem/MenuItem';
import Icon from '../../../common/Icon/Icon';
import SubMenu from '../SubMenu/SubMenu';
import { toggleMobileMenu } from '../../../../redux/actions/site.actions';
import styles from './MenuLink.module.scss';

const mobileMenuMaxSize = 767;

class MenuLink extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handlePointerEnter = this.handlePointerEnter.bind(this);
        this.handlePointerLeave = this.handlePointerLeave.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
    }

    state = {
        isOpen: false,
    };

    componentDidUpdate(prevProps) {
        if (this.state.isOpen) {
            const routeHasChanged =
                this.props.router.asPath !== prevProps.router.asPath;
            const mobileMenuHasClosed =
                prevProps.isMobileMenuActive === true &&
                this.props.isMobileMenuActive === false;
            if (routeHasChanged || mobileMenuHasClosed) {
                this.setState({ isOpen: false });
            }
        }
    }

    render() {
        const {
            href,
            children,
            links,
            dispatchtoggleMobileMenu,
            isMobileMenuActive,
            router,
            className,
            ...props
        } = this.props;

        const isCurrentPage = router.asPath === href;
        const hasChildren = links && links.length > 0;

        return (
            <MenuItem
                className={classNames({
                    [styles.menuLink]: true,
                    [styles.isCurrentPage]: isCurrentPage,
                    [styles.hasChildren]: hasChildren,
                    [className]: !!className,
                })}
                onPointerEnter={this.handlePointerEnter}
                onPointerLeave={this.handlePointerLeave}
                {...props}
            >
                <Link href={href}>
                    <a onClick={this.handleClick}>
                        {children}
                        <Icon className={styles.moreIcon} icon="chevron" />
                    </a>
                </Link>
                {hasChildren && (
                    <SubMenu
                        href={href}
                        isOpen={this.state.isOpen}
                        links={links}
                        onBack={this.handleBackClick}
                    >
                        {children}
                    </SubMenu>
                )}
            </MenuItem>
        );
    }

    handlePointerEnter(event) {
        event.preventDefault();

        const { links } = this.props;
        const hasChildren = links !== undefined && links.length > 0;

        if (
            !this.state.isOpen &&
            hasChildren &&
            event.pointerType === 'mouse' &&
            !isMobileMenu()
        ) {
            this.setState({ isOpen: true });
        }
    }

    handlePointerLeave(event) {
        event.preventDefault();
        const { links } = this.props;
        const hasChildren = links !== undefined && links.length > 0;

        if (
            this.state.isOpen &&
            event.pointerType === 'mouse' &&
            !event.relatedTarget.contains(event.currentTarget) &&
            !isMobileMenu() &&
            hasChildren
        ) {
            this.setState({ isOpen: false });
        }
    }

    handleBackClick() {
        if (this.state.isOpen) {
            this.setState({ isOpen: false });
        }
    }

    handleClick(event) {
        event.preventDefault();
        const { links, href, dispatchtoggleMobileMenu, router } = this.props;
        const hasChildren = links !== undefined && links.length > 0;

        if (hasChildren) {
            this.setState({ isOpen: !this.state.isOpen });
        } else {
            router.push(href);
            dispatchtoggleMobileMenu();
        }
    }
}

function isMobileMenu() {
    return window.innerWidth <= mobileMenuMaxSize;
}

MenuLink.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    links: PropTypes.array,
    isMobileMenuActive: PropTypes.bool.isRequired,
    dispatchtoggleMobileMenu: PropTypes.func.isRequired,
};

function mapStateToProps({ site }) {
    return {
        isMobileMenuActive: site.isMobileMenuActive,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatchtoggleMobileMenu: (params) =>
            dispatch(toggleMobileMenu(params)),
    };
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(MenuLink);
