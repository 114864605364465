import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import Icon from '../../../common/Icon/Icon';
import { toggleMobileMenu } from '../../../../redux/actions/site.actions';
import styles from './MobileMenuButton.module.scss';

function MobileMenuButton({ className, ...props }) {
    const isMobileMenuActive = useSelector(
        ({ site }) => site.isMobileMenuActive
    );
    const dispatch = useDispatch();
    const dispatchToggleMobileMenu = (params) =>
        dispatch(toggleMobileMenu(params));
    return (
        <button
            className={classNames({
                [styles.mobileMenuButton]: true,
                [styles.isActive]: isMobileMenuActive,
                [className]: !!className,
            })}
            onClick={dispatchToggleMobileMenu}
            type="button"
            aria-label="Toggle menu"
            {...props}
        >
            {isMobileMenuActive ? (
                <Icon
                    className={styles.closeIcon}
                    height={40}
                    width={40}
                    icon="times"
                />
            ) : (
                <Icon
                    className={styles.menuIcon}
                    height={35}
                    width={40}
                    icon="menu"
                />
            )}
        </button>
    );
}

MobileMenuButton.propTypes = {
    className: PropTypes.string,
};

export default MobileMenuButton;
