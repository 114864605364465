import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../common/Icon/Icon';
import MobileMenuButton from './MobileMenuButton/MobileMenuButton';
import abtaLogo from '../../../../public/images/abta/abta-black.svg';
import atolLogoWithLabel from '../../../../public/images/atol/atol-with-label.svg';
import {
    displayTelephoneNumber,
    telephoneNumber,
} from '../../../utils/constants';
import styles from './HeaderTop.module.scss';

function HeaderTop({ className, ...props }) {
    return (
        <nav className={classNames(styles.headerTop, className)} {...props}>
            <div className={styles.wrapper}>
                <Link href="/">
                    <a className={styles.home}>Home</a>
                </Link>

                <Link href="/abta-member">
                    <a className={styles.abta} aria-label="ABTA member">
                        <Image
                            alt="ABTA member"
                            width={80}
                            height={31}
                            src={abtaLogo}
                        />
                    </a>
                </Link>

                <Link href="/atol-member">
                    <a className={styles.atol} aria-label="ATOL member">
                        <Image
                            alt="ATOL protected"
                            width={80}
                            height={36}
                            src={atolLogoWithLabel}
                        />
                    </a>
                </Link>

                <div className={styles.rightContent}>
                    <a
                        className={styles.callUs}
                        href={`tel:${telephoneNumber}`}
                        aria-label="Call us"
                    >
                        <Icon className={styles.icon} icon="phone" />
                        <span className={styles.displayNumber}>
                            {displayTelephoneNumber}
                        </span>
                    </a>
                </div>

                <MobileMenuButton className={styles.menuButton} />
            </div>
        </nav>
    );
}

HeaderTop.propTypes = {
    className: PropTypes.string,
};

export default HeaderTop;
