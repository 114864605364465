import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SocialIcon from './SocialIcon/SocialIcon';
import { contactEmail, telephoneNumber } from '../../../utils/constants';
import emailLogo from '../../../../public/images/social/envelope-square.svg';
import facebookLogo from '../../../../public/images/social/facebook-square.svg';
import instagramLogo from '../../../../public/images/social/instagram-square.svg';
import linkedInLogo from '../../../../public/images/social/linkedin-square.svg';
import phoneLogo from '../../../../public/images/social/phone-square.svg';

import styles from './Social.module.scss';

function Social({ className, ...props }) {
    return (
        <ul className={classNames(styles.social, className)} {...props}>
            <SocialIcon
                imageSrc={facebookLogo}
                href="https://www.facebook.com/TheHolidayPeople"
                socialType="facebook"
            />
            <SocialIcon
                imageSrc={instagramLogo}
                href="https://www.instagram.com/wearetheholidaypeople/"
                socialType="instagram"
            />
            <SocialIcon
                imageSrc={linkedInLogo}
                href="https://www.linkedin.com/company/the-holiday-people/"
                socialType="linkedin"
            />
            <SocialIcon
                imageSrc={phoneLogo}
                href={`tel:${telephoneNumber}`}
                socialType="phone"
            />
            <SocialIcon
                imageSrc={emailLogo}
                href={`mailto:${contactEmail}`}
                socialType="email"
            />
        </ul>
    );
}

Social.propTypes = {
    className: PropTypes.string,
};

export default Social;
