import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './MenuItem.module.scss';

function MenuItem({ children, className, ...props }) {
    return (
        <li className={classNames(styles.menuItem, className)} {...props}>
            {children}
        </li>
    );
}

MenuItem.propTypes = {
    className: PropTypes.string,
};

export default MenuItem;
