import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Social from './Social/Social';
import Links from './Links/Links';
import Address from '../common/Address/Address';
import styles from './Footer.module.scss';

function Footer({ className, ...props }) {
    const copyrightYear = new Date().getFullYear();
    return (
        <footer className={classNames(styles.footer, className)} {...props}>
            <Social className={styles.social} />
            <Links className={styles.links} />
            <Address className={styles.address} />
            <ul className={styles.copyright}>
                <li>
                    © {copyrightYear} World Adventures ltd. All rights reserved.
                </li>
                <li className="registration">
                    Registered in England and Wales, No: 10367760 VAT No:
                    290274304
                </li>
            </ul>
        </footer>
    );
}

Footer.propTypes = {
    className: PropTypes.string,
};

export default Footer;
