import { SITE_TOGGLE_MOBILE_MENU, SITE_CLOSE_MOBILE_MENU } from './types';

export const toggleMobileMenu = () => {
    return {
        type: SITE_TOGGLE_MOBILE_MENU,
    };
};

export const closeMobileMenu = () => {
    return {
        type: SITE_CLOSE_MOBILE_MENU,
    };
};
