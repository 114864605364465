import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { address } from '../../../utils/constants';
import styles from './Address.module.scss';

function Address({ includeCompany = false, className, ...props }) {
    return (
        <ul className={classNames(styles.address, className)} {...props}>
            {includeCompany && <li>The Holiday People</li>}
            {address.map((line) => (
                <li key={line}>{line}</li>
            ))}
        </ul>
    );
}

Address.propTypes = {
    includeCompany: PropTypes.bool,
    className: PropTypes.string,
};

export default Address;
