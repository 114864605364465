import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { origin } from '../../utils/constants';

function PageMetadata({ title, description, relativeUrl, imageUrl, robots }) {
    const url = `${origin}${relativeUrl}`;

    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={description} key="description" />
            {(relativeUrl || relativeUrl === '') && (
                <link rel="canonical" href={url} key="canonical" />
            )}
            {robots && <meta name="robots" content={robots} key="robots" />}
            <meta property="og:locale" content="en_GB" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={url} />
            <meta property="og:site_name" content="The Holiday People" />
            <meta property="og:image" content={getImage(imageUrl)} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:image" content={getImage(imageUrl)} />
        </Head>
    );
}

const getImage = (imageUrl) => {
    if (!imageUrl) {
        return `${origin}/images/logos/the-holiday-people-icon.png`;
    }
    return imageUrl;
};

PageMetadata.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    relativeUrl: PropTypes.string,
    robots: PropTypes.string,
    imageUrl: PropTypes.string,
};

export default PageMetadata;
