import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Icon.module.scss';
import { getIcon, availableIcons } from './iconHelper';

const Icon = ({
    icon,
    height = 30,
    width = 30,
    style,
    className,
    color,
    ...props
}) => {
    const SvgIcon = getIcon(icon);
    if (!SvgIcon) {
        return null;
    }
    return (
        <SvgIcon
            height={height}
            width={width}
            className={classNames(styles.icon, className)}
            style={{ fill: `#${color}`, stroke: `#${color}`, ...style }}
            fill={color}
            {...props}
        />
    );
};

Icon.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    icon: PropTypes.oneOf(availableIcons).isRequired,
    color: PropTypes.string,
};

export default Icon;
