import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import MenuLink from './MenuLink/MenuLink';
import styles from './HeaderMenu.module.scss';

const aboutUsLinks = [
    { href: '/faqs', children: 'FAQs' },
    { href: '/giving-back', children: 'Giving back' },
    { href: '/jobs', children: 'Work with us' },
];

const bookWithConfidenceLinks = [
    { href: '/financial-protection', children: 'Financial protection' },
    { href: '/atol-member', children: 'ATOL member' },
    { href: '/abta-member', children: 'ABTA member' },
    { href: '/payment-options', children: 'Payment options' },
    { href: '/travel-updates', children: 'Travel updates' },
];

function HeaderMenu({ className, ...props }) {
    const isMobileMenuActive = useSelector(
        ({ site }) => site.isMobileMenuActive
    );
    return (
        <nav
            className={classNames({
                [styles.headerMenu]: true,
                [styles.isActive]: isMobileMenuActive,
                [className]: !!className,
            })}
            {...props}
        >
            <div className={styles.wrapper}>
                <ul className={styles.menuItems}>
                    <MenuLink
                        className={classNames(styles.menuItem, styles.homeLink)}
                        href="/"
                    >
                        Home
                    </MenuLink>
                    <MenuLink className={styles.menuItem} href="/destinations">
                        Destinations
                    </MenuLink>
                    <MenuLink className={styles.menuItem} href="/tailor-made">
                        Tailor made
                    </MenuLink>
                    <MenuLink
                        className={styles.menuItem}
                        href="/book-with-confidence"
                        links={bookWithConfidenceLinks}
                    >
                        Book with confidence
                    </MenuLink>
                    <MenuLink
                        className={styles.menuItem}
                        href="/about-us"
                        links={aboutUsLinks}
                    >
                        About us
                    </MenuLink>
                    <MenuLink className={styles.menuItem} href="/contact-us">
                        Contact us
                    </MenuLink>
                </ul>
            </div>
        </nav>
    );
}

HeaderMenu.propTypes = {
    className: PropTypes.string,
};

export default HeaderMenu;
