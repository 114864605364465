import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from 'next/image';
import styles from './SocialIcon.module.scss';

function SocialIcon({ socialType, href, imageSrc, className, ...props }) {
    return (
        <li className={classNames(styles.socialIcon, className)} {...props}>
            <a href={href} target="_blank" rel="noreferrer">
                <Image src={imageSrc} width={40} height={40} alt={socialType} />
            </a>
        </li>
    );
}

SocialIcon.propTypes = {
    socialType: PropTypes.oneOf([
        'email',
        'facebook',
        'instagram',
        'linkedin',
        'phone',
        'twitter',
    ]).isRequired,
    href: PropTypes.string.isRequired,
    imageSrc: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default SocialIcon;
