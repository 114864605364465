import buildingIcon from '../../../../public/images/icons/building.svg?inline';
import builtForYouIcon from '../../../../public/images/icons/built-for-you.svg?inline';
import checkIcon from '../../../../public/images/icons/check.svg?inline';
import chevronIcon from '../../../../public/images/icons/chevron.svg?inline';
import clockIcon from '../../../../public/images/icons/clock.svg?inline';
import coinsIcon from '../../../../public/images/icons/coins.svg?inline';
import commentIcon from '../../../../public/images/icons/comment.svg?inline';
import envelopeIcon from '../../../../public/images/icons/envelope.svg?inline';
import flexibleIcon from '../../../../public/images/icons/flexible.svg?inline';
import flightIcon from '../../../../public/images/icons/flight.svg?inline';
import menuIcon from '../../../../public/images/icons/menu.svg?inline';
import phoneIcon from '../../../../public/images/icons/phone.svg?inline';
import shieldIcon from '../../../../public/images/icons/shield.svg?inline';
import teamIcon from '../../../../public/images/icons/team.svg?inline';
import timesIcon from '../../../../public/images/icons/times.svg?inline';

const iconFileMapper = {
    building: buildingIcon,
    builtForYou: builtForYouIcon,
    check: checkIcon,
    chevron: chevronIcon,
    clock: clockIcon,
    coins: coinsIcon,
    comment: commentIcon,
    envelope: envelopeIcon,
    flexible: flexibleIcon,
    flight: flightIcon,
    menu: menuIcon,
    phone: phoneIcon,
    shield: shieldIcon,
    team: teamIcon,
    times: timesIcon,
};

export function getIcon(icon) {
    return iconFileMapper[icon];
}

export const availableIcons = Object.keys(iconFileMapper);
